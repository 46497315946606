.App{
  display: flex;
  background-color: aliceblue;
 
  
}
.panel{
  position: -webkit-sticky;
  position: sticky;
  left: 0;
 z-index: 2;
}
.Sidepanel{
  height: 100vh;
  width: 200px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #ffffff, #fff0de);
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.nav-1, .nav-2 ,.nav-3, .nav-4, .nav-5{
  color: #ff6f00;
  transform: rotate(-90deg);
  font-size: 15px;
  font-weight: bolder;
}
/*
.trackingline{
 width:5px;
 color: black;
 height: 20rem;
 background-color: black;
}*/
.intro{
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle{
  border-radius: 100%;
  height: 30rem;
  width:30rem;
  transform:translate(15rem);
  background: linear-gradient(to right, #ff920cd9, #ff920c);
 
}
.semicircle{
  transform:translate(0,1rem);
  width: 16rem;
  height: 32rem;
  border-radius: 0 16rem 16rem 0;
  background: linear-gradient(to right, #FE9A1F, #ff6f00);

}
.title{
  display: flex;
  font-size: 50px;
  color: orange;
}
.title-1{
  color: white;
  transform:translate(-20rem);
  z-index: 1;
}
.title-2{
  color: #FF7001;
  transform:translate(-18rem);
}
.ourbrand{
  

}